import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import { arrayToChunks } from 'js/utils'
import ImageWrap from 'components/ImageWrap'
import ModalVideo from 'components/ModalVideo'

const Block = ({ content, locale }) => {
  const { items } = content
  const itemChunks = arrayToChunks(items, 2)

  return (
    <div className={styles.videos}>
      <div className={`row ${styles.productAnim}`}>
        <div className='col-10 offset-lg-1'>
          {itemChunks.map((row, i) => (
            <div className='row' key={i}>
              {row.map((item, j) => (
                <Item content={item} key={j} locale={locale} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const Item = ({ content, locale }) => {
  const { title, image } = content
  return (
    <div className='col-6'>
      <div className={styles.video}>
        <div className={styles.holder}>
          <h3>{title}</h3>
          <div className={styles.image}>
            <ImageWrap image={image} />
          </div>
          <i className='fal fa-play'></i>
          <ModalVideo content={content} locale={locale} />
        </div>
      </div>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment GridVideosInnerEn on MarkdownRemarkFrontmatterEnBlocks {
    items {
      title
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
      videoSlug
    }
  }
`
export const fragmentZh = graphql`
  fragment GridVideosInnerZh on MarkdownRemarkFrontmatterZhBlocks {
    items {
      title
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
      videoSlug
    }
  }
`
