import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'

const Block = ({ content }) => {
  const { title, excerpt, items } = content
  const colSpan = items.length === 4 ? 3 : 4
  return (
    <div className={`row ${styles.component} ${styles.productAnim}`}>
      <div className='col-10 offset-lg-1'>
        <div className={styles.available}>
          <h2>{title}</h2>
          <p>{excerpt}</p>
          <div className='container'>
            <div className='row'>
              {items.map((item, i) => {
                return (
                  <div
                    className={`col-md-${colSpan} col-sm-12 ${styles.col}`}
                    key={i}
                  >
                    <div className={styles.inner}>
                      <MarkdownWrap body={item.body} />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment TextColumnsEn on MarkdownRemarkFrontmatterEnBlocks {
    title
    excerpt
    items {
      body
    }
  }
`
export const fragmentZh = graphql`
  fragment TextColumnsZh on MarkdownRemarkFrontmatterZhBlocks {
    title
    excerpt
    items {
      body
    }
  }
`
