import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'
import ImageWrap from 'components/ImageWrap'
import { textAddSpans } from 'js/utils'
import Inview from 'components/Inview'

const Block = ({ content }) => {
  const { title, subtitle, body, image, imageMobile } = content
  const titleSpan = textAddSpans(title)

  return (
    <Inview className={styles.component}>
      <div
        className={`row ${styles.productAnim} in in-fade-in-up-100 stagger-100`}
      >
        <div className='col-10 offset-lg-1'>
          <h1 dangerouslySetInnerHTML={{ __html: titleSpan }} />
        </div>
      </div>
      <div
        className={`row ${styles.productAnim} in in-fade-in-up-100 stagger-200`}
      >
        <div className='offset-lg-1 col-lg-4 col-md-10 col-sm-10 col-xs-10'>
          {subtitle && <h2>{subtitle}</h2>}
          <MarkdownWrap body={body} />
          <div className={styles.illustration}>
            <div className='d-none d-lg-block'>
              <ImageWrap image={image} />
            </div>
            <div className='d-lg-none'>
              {/* <ImageWrap image={imageMobile} /> */}
              <img src={imageMobile.publicURL} alt='' />
            </div>
          </div>
        </div>
      </div>
    </Inview>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment TextOffsetImageEn on MarkdownRemarkFrontmatterEnBlocks {
    title
    subtitle
    body
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
    imageMobile {
      childImageSharp {
        gatsbyImageData
      }
      publicURL
    }
  }
`
export const fragmentZh = graphql`
  fragment TextOffsetImageZh on MarkdownRemarkFrontmatterZhBlocks {
    title
    subtitle
    body
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
    imageMobile {
      childImageSharp {
        gatsbyImageData
      }
      publicURL
    }
  }
`
