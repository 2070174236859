import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'

const Block = ({ content }) => {
  const { body } = content
  return (
    <div className={`row ${styles.productAnim}`}>
      <div className='offset-lg-1 col-lg-10 col-md-10 col-sm-10 col-xs-10'>
        <MarkdownWrap body={body} />
      </div>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment TextNotesEn on MarkdownRemarkFrontmatterEnBlocks {
    body
  }
`
export const fragmentZh = graphql`
  fragment TextNotesZh on MarkdownRemarkFrontmatterZhBlocks {
    body
  }
`
