import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'
import ImageWrap from 'components/ImageWrap'

const Block = ({ content }) => {
  const { title, body, image, imageMobile } = content
  return (
    <div className={styles.component}>
      <div className='row product-anim'>
        <div className='col-10 offset-lg-1'>
          <div className={styles.car}>
            <h2 className='d-none d-lg-block'>{title}</h2>
            <div className='row'>
              <div className='col-lg-4 col-xs-10'>
                <div className={`${styles.image} d-none d-lg-block`}>
                  <MarkdownWrap body={body} />
                </div>
              </div>
              <div className='col-lg-8 col-xs-10'>
                <div className={`${styles.image} d-none d-lg-block`}>
                  <ImageWrap image={image} />
                </div>
                <div className={`${styles.image} d-lg-none`}>
                  {/* <ImageWrap image={imageMobile} /> */}
                  <img src={imageMobile.publicURL} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment TextOffsetImageInnerEn on MarkdownRemarkFrontmatterEnBlocks {
    title
    body
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
    imageMobile {
      childImageSharp {
        gatsbyImageData
      }
      publicURL
    }
  }
`
export const fragmentZh = graphql`
  fragment TextOffsetImageInnerZh on MarkdownRemarkFrontmatterZhBlocks {
    title
    body
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
    imageMobile {
      childImageSharp {
        gatsbyImageData
      }
      publicURL
    }
  }
`
